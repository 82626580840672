import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"


const Container = styled.section`
  max-width: 130rem;
  margin: 3rem auto;
  padding: 0 1.5rem;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  div {
    z-index: 10;
    margin: 1.5rem;
    width: 100%;
    max-width: 26rem;
  }
`

const Svg = styled.img`
  height: 10rem;
  width: 10rem;
`

const Title = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  color: #505050;
`

const Body = styled.p`
  padding: 1.5rem 0;
  font-weight: 400;
  color: #505050;
`

const PerkSquaresDynamic = ({ data: { title, subtitle, data } }) => {
  const [newBodyArr, setNewBodyArr] = useState([])

  useEffect(() => {
    const getNewBodyArr = async() => {
      // Array that will temporarily hold the new body values that will be displayed
      let newBodyArrTemp = []

      for (const [idx, datum] of data.entries()) {
        let datumEndpoints = datum.endpointList,
        datumBody = datum.body

        // If the current data item has (values for) an endpointList markdown key
        if (datumEndpoints) {
          // Fetch all the endpoint key values for the current data item into an array variable and await the resolution
          let endpointData = await Promise.all(datumEndpoints.map((ep) => fetchEndpoint(ep.url, ep.key)))

          // Replace the placeholders with their corresponding fetched key values and add to the temporary body values array for the current index
          newBodyArrTemp[idx] = replacePlaceholders(datumBody, endpointData)
        }
        else {
          // Otherwise if the data item did not have an endpointList markdown key, then add the unmodified body to the temporry values array for the current index
          newBodyArrTemp[idx] = datumBody
        }
      }

      // Set new body values state as the temporary body values array
      setNewBodyArr(newBodyArrTemp)
    }

    const fetchEndpoint = async (endpointUrl, desiredKey) => {
      const res = await fetch(
        endpointUrl,
        {
          method: "GET",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
          },
        }
      ).catch((error) => {
        console.error(error)
      });

      if (res && res.ok) {
        const json = await res.json()
        return json[desiredKey]
      }

      // Returning undefined for error or unsuccessful response is setup such that the default values will replace the placeholders
      return
    }

    const replacePlaceholders = (bodyStr, endpointDataArr) => {
      // RegEx that matches the placeholder format (i.e. [epx|default] where 'x' in 'epx' is a digit and 'default' can be any value)
      let rx = /\[ep(\d+)\|(.*?)\]/g
  
      // p1 indicates which value in the array of fetched key-values is to replace the placeholder (i.e. the 'x' in 'epx')
      // p2 represents the default value to replace the placeholder when the fetch call is unsuccessful
      let newBody = bodyStr.replace(rx, (match, p1, p2) => {
        // Grab the corresponding fetched key-value to do the replacement
        let endpointRes = endpointDataArr[p1 - 1]

        // If there exists a corresponding fetched key-value, use it to replace the placeholder
        if (endpointRes) {
          // If it is a valid number value, add applicable number commas beforehand
          endpointRes = !isNaN(endpointRes) ? toRoundedNumberWithCommas(endpointRes) : endpointRes
          return endpointRes
        }

        // Otherwise if there does not exist a corresponding fetched key-value then replace the placeholder with the default value
        return p2
      })
      
      // Return the modified body with the replaced placeholder(s)
      return newBody
    }

    const toRoundedNumberWithCommas = (num) => {
      // Round down to the next multiple of 5
      num = Math.floor(num/5)*5;
      
      return num.toLocaleString();
    }

    getNewBodyArr()
  }, [])

  return (
    <Container>
    <Primary>{title}</Primary>
    <Subtitle>{subtitle}</Subtitle>
    <Content>
      {data &&
      data.map(({ title, body, image }, count) => (
        <div key={count}>
        <Svg
          src={image.relativePath ? `/img/${image.relativePath}` : image}
          alt="Perk image"
        />
        <Title>{title}</Title>
        <Body>{newBodyArr[count]}</Body>
        </div>
      ))}
    </Content>
    </Container>
  )
}

export default PerkSquaresDynamic
