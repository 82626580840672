import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import DetailedSteps from "../components/DetailedSteps"
import PerkSquaresDynamic from "../components/PerkSquaresDynamic"
import Questions from "../components/Questions"
import InternalCards from "../components/InternalCards"
import Cta from "../components/Cta"
import Ready from "../components/Ready"

export const ServicesPostTemplate = ({
  title,
  subtitle,
  reviews,
  detailedSteps,
  perkSquares,
  questions,
  breadcrumbs,
}) => (
  <>
    <Header title={title} subtitle={subtitle}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 165.368 13.234">
        <path d="M33.117 52.247C23.16 47.197 11.823 42.6-.493 35.08-12.745 27.54-25.978 17.08-21.672 9.479c4.24-7.584 25.3-5.473 45.115-3.47 19.853 2.037 38.498 3.999 54.15 5.27 15.599 1.263 28.96-4.969 51.331-8.11C151.35.032 182.781-.012 189.3 5.958c6.504 5.996-11.934 18.06-21.307 28.508-9.32 10.456-9.587 19.323-20.305 22.092-10.771 2.761-31.993-.576-48.355.362-16.31.945-27.76 6.166-37.717 6.02-9.957-.146-18.525-5.67-28.498-10.694z" />
      </svg>
    </Header>
    <Breadcrumbs data={breadcrumbs} />
    <DetailedSteps data={detailedSteps} reviews={reviews} />
    <PerkSquaresDynamic data={perkSquares} />
    <Questions data={questions} />
    <InternalCards recycling locations pricing />
    <Ready>
      <Cta href="https://app.skipthedepot.com/register">Get started</Cta>
      <Cta to="/faq" variant>
        Visit our FAQ
      </Cta>
    </Ready>
  </>
)

const ServicesPost = ({
  data: {
    markdownRemark: {
      frontmatter: {
        title,
        subtitle,
        seoDesc,
        tag,
        reviews,
        detailedSteps,
        perkSquares,
        questions,
      },
    },
  },
  location,
}) => {
  const breadcrumbs = location.pathname.split("/")
  return (
    <Layout title={tag} seoDesc={seoDesc}>
      <ServicesPostTemplate
        title={title}
        subtitle={subtitle}
        reviews={reviews}
        detailedSteps={detailedSteps}
        perkSquares={perkSquares}
        questions={questions}
        breadcrumbs={breadcrumbs}
      />{" "}
    </Layout>
  )
}

export default ServicesPost

export const pageQuery = graphql`
  query ServicesPostById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        subtitle
        seoDesc
        tag
        reviews {
          author
          body
          url
        }
        detailedSteps {
          title
          subtitle
          data {
            title
            body
          }
          video
        }
        perkSquares {
          title
          subtitle
          data {
            title
            body
            image {
              relativePath
            }
            endpointList {
              url
              key
            }
          }
        }
        questions {
          title
          subtitle
          data {
            title
            body
          }
        }
      }
    }
  }
`
